import * as React from 'react'

interface IPropTypes {
  title: string
  subtitle: string
  description: string
}

const ProjectCard = ({ title, subtitle, description }: IPropTypes) => {
  return (
    <div className="col-span-12">
      <div className="transition ease-in-out hover:-translate-y-1 border border-4 border-[#705ef2] p-4 mt-2 rounded-lg">
        <h4 className="text-primary text-2xl font-bold">{title}</h4>
        <div className="flex flex-row gap-x-4">
          <p className="text-sm text-primary my-1">{subtitle}</p>
        </div>
        <p className="text-primary opacity-60 mt-2">{description}</p>
      </div>
    </div>
  )
}

export default ProjectCard
