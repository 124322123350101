import * as React from 'react'

import { graphql, Link } from 'gatsby'

import Layout from '../components/core/Layout'
import ProjectCard from '../components/Cards/Standard/ProjectCard'
import SearchInput from '../components/Input/SearchInput'

// Should have the projects shown as cards in a grid
// Needs pagination and search
const Projects = ({ data }: any) => {
  const [state, setState] = React.useState({
    filteredPosts: [],
    query: '',
  })

  const allProjects = data.allMdx.nodes
  const projects = state.query ? state.filteredPosts : allProjects

  const handleInputChange: React.ChangeEventHandler = (event: any) => {
    const query = event.target.value
    const filteredPosts = allProjects.filter((post: any) => {
      const { title } = post.frontmatter
      return title.toLowerCase().includes(query.toLowerCase()) // ||
    })
    setState({
      query,
      filteredPosts,
    })
  }

  return (
    <Layout>
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-col mx-6 mt-12">
          <h1 className="font-bold text-primary text-5xl mb-4">Projects</h1>
          <p className="mb-4">
            Here's a lit of some of the projects that I've worked on and things
            that I've built. Check out any of the projects for more detail. I
            recently embarked on a mission to rebuilt this site - more content
            coming soon!
          </p>
          <SearchInput
            className="col-span-12"
            value={state.query}
            onChange={handleInputChange}
          />
          <div className="grid grid-cols-12 gap-4">
            {projects.map((project: any) => {
              return (
                <div className="col-span-12">
                  <Link to={`/projects/${project.slug}`} key={project.slug}>
                    <ProjectCard
                      title={project.frontmatter.title}
                      subtitle={project.frontmatter.subtitle}
                      description={project.frontmatter.description}
                    />
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const projectsQuery = graphql`
  {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "projects" } } }
    ) {
      nodes {
        slug
        frontmatter {
          title
          subtitle
          description
        }
      }
    }
  }
`

export default Projects
